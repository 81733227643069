import fetch from "@/utils/service";
// 企业名录
export function getEnterpriseList(params) {
  return fetch({
    url: "/v1.0/api/enterprise/list",
    params: params,
  });
}
// 特产
export function getProductList(params) {
  return fetch.post("/v1.0/api/specialty/list", params);
}
// 游玩
export function getPlayList(params) {
  return fetch.post("/v1.0/api/play/list", params);
}
// 美食
export function getFoodList(params) {
  return fetch.post("/v1.0/api/food/list", params);
}
// 住宿
export function getHouseList(params) {
  return fetch.post("/v1.0/api/house/list", params);
}
// 产业概述
export function getIndustryDetail(params) {
  return fetch({
    url: "/v1.0/api/industry/profile",
    params: params,
  });
}
<template>
  <dv-full-screen-container class="bg">
    <!-- 顶部 -->
    <Header
      pageNum="4/7"
      :villageName="detail.village_name"
      :slogan="detail.slogan"
      :curCountDown="curCountDown"
      @onShowNext="onShowNext"
    />
    <!-- <div class="header">
      <div class="header__left">百川汇海 胜在齐心</div>
      <div class="header__title">
        <div>乡村振兴 数字</div>
        <div class="name">百胜</div>
      </div>
      <div class="header__right">
        翻页倒计时 {{ curCountDown }}s
        <span class="header__page">4/7</span>
      </div>
    </div> -->
    <div class="main">
      <div class="m__left">
        <div class="m__left-top">
          <div class="m__title">明星企业 TOP4/10</div>
          <div class="m__list f-fw">
            <div
              class="m__list-item"
              v-for="(item, index) of enterpriseList"
              :key="index"
            >
              <img :src="item.cover_img" alt="" />
              <div class="ml-19 f-1 f-d-c f-j-sb">
                <div class="title">{{ item.title }}</div>
                <div>
                  <div class="txt">注册资本:{{ item.registeredCapital }}</div>
                  <div class="txt">成立时间: {{ item.reTime }}</div>
                  <div class="txt">注册地址：{{ item.registeredAddress }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m__left-bottom">
          <div class="m__title">
            乡村旅游 <span class="ml-20">游｜食｜宿｜购</span>
          </div>
          <vueSeamlessScroll
            class="seamless-warp"
            :data="travel"
            :class-option="defaultOption"
          >
            <div class="m__list f-fw">
              <div
                class="m__list-item"
                v-for="(item, index) of travel"
                :key="index"
              >
                <img :src="item.url" alt="" />
                <div class="ml-14 f-1 f-d-c f-j-sb">
                  <div>
                    <div class="title">
                      【{{
                        item.type === 1
                          ? "旅游"
                          : item.type === 2
                          ? "美食"
                          : item.type === 3
                          ? "住宿"
                          : "特产"
                      }}】
                    </div>
                    <div class="title mt">{{ item.name }}</div>
                  </div>

                  <div class="zanTop f-j-sb">
                    <span class="zan f-ai-c">
                      <img
                        class="mr-5"
                        src="../assets/industry/乡村旅游点赞@2x.png"
                        alt=""
                      />
                      {{ item.support_count }}
                    </span>
                    <span class="zan-label"
                      ><em>{{ index }}</em
                      >/{{ travel.length }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </vueSeamlessScroll>
        </div>
      </div>
      <div class="m__right">
        <div class="m__title">产业概述</div>
        <div class="m__instr">
          {{ industry.introduction }}
        </div>
        <div class="m__image f-fw">
          <div class="item" v-for="(img, index) of industry.imgs" :key="index">
            <img :src="img" alt="" />
          </div>
        </div>
      </div>
      <div class="code f-ai-c">
        <div class="f-1">
          <div class="txt f-ai-c">
            <img class="view" src="../assets/industry/查看@2x.png" alt="" />
            {{ config.follow_count }}人
          </div>
          <div class="txt f-ai-c mt-10">
            <img class="click" src="../assets/industry/点击@2x.png" alt="" />
            {{ config.visit_count || 0 }}次
          </div>
        </div>
        <img class="icon" :src="config.qrcode_img" />
        <!-- <img class="icon" src="../assets/industry/小程序@2x.png" alt="" /> -->
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import {
  getEnterpriseList,
  getIndustryDetail,
  getPlayList,
  getProductList,
  getFoodList,
  getHouseList,
} from "../api/industry";
import { getConfig } from "@/api/village";
import { formatDate } from "../utils/format.js";
import { getHomepage } from "@/api/survey/index";
import vueSeamlessScroll from "vue-seamless-scroll";
import Header from "./components/Header";
export default {
  data() {
    return {
      id: "6",
      enterpriseList: [],
      params: {
        village_id: "6",
        page: 1,
        rows: 9999,
      },
      industry: {},
      travel: [],
      config: {},
      curCountDown: 60,
      interval: null,
      detail:{},
      defaultDetail: {
      },
    };
  },
  components: {
    vueSeamlessScroll,
    Header,
  },
  computed: {
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  mounted() {
    const id = this.$route.query.village_id || "6";
    this.id = id;
    this.getHomepage(id);
    this.getConfig(id);
    this.getEnterpriseList();
    this.getIndustry();
    this.getPlayList();
    this.getFoodList();
    this.getHouseList();
    this.getProductList();
  },
  methods: {
    onShowNext() {
      clearInterval(this.interval);
      this.$router.push({
        path: "character",
        query: { village_id: this.id },
      });
    },
    getHomepage(id) {
      getHomepage({ village_id: id }).then((res) => {
        console.log(res);
        this.detail = res.data.data || this.defaultDetail;
      });
    },
    async getEnterpriseList() {
      const { data } = await getEnterpriseList({
        village_id: this.id,
        page: 1,
        rows: 9999,
      });
      const { pagedata } = data.data;
      // console.log(pagedata)
      this.enterpriseList = pagedata.slice(0, 4).map((item) => ({
        ...item,
        time: formatDate(item.time, "yyyy年MM月dd日"),
        reTime: formatDate(item.registered_time, "yyyy年MM月dd日"),
      }));
      console.log(this.enterpriseList);
    },
    async getIndustry() {
      const { data } = await getIndustryDetail({ village_id: this.id });
      console.log(data.data);
      this.industry = data.data;
    },
    async getPlayList() {
      const { data } = await getPlayList({
        village_id: this.id,
        page: 1,
        rows: 9999,
      });
      const { pagedata } = data.data;
      console.log(pagedata);
      const arr = pagedata.map((item) => ({
        ...item,
        url: item.banner_url.split(",")[0],
        type: 1,
        name: item.play_name,
      }));
      this.travel = this.travel.concat(arr);
      console.log(this.travel);
    },
    async getFoodList() {
      const { data } = await getFoodList({
        village_id: this.id,
        page: 1,
        rows: 9999,
      });
      const { pagedata } = data.data;
      const arr = pagedata.map((item) => ({
        ...item,
        url: item.banner_url.split(",")[0],
        type: 2,
        name: item.food_name,
      }));
      this.travel = this.travel.concat(arr);
      console.log(this.travel);
    },
    async getHouseList() {
      const { data } = await getHouseList({
        village_id: this.id,
        page: 1,
        rows: 9999,
      });
      const { pagedata } = data.data;
      const arr = pagedata.map((item) => ({
        ...item,
        url: item.banner_url.split(",")[0],
        type: 3,
        name: item.house_name,
      }));
      this.travel = this.travel.concat(arr);
    },
    async getConfig(id) {
      const { data } = await getConfig({
        village_id: id,
      });
      if (data.code === "200") {
        this.config = data.data;
        this.setTimer(data.data.countdown);
      }

      console.log("data", data);
    },
    async getProductList() {
      const { data } = await getProductList({
        village_id: this.id,
        page: 1,
        rows: 9999,
      });
      const { pagedata } = data.data;
      const arr = pagedata.map((item) => ({
        ...item,
        url: item.banner_url.split(",")[0],
        type: 4,
        name: item.specialty_name,
      }));
      this.travel = this.travel.concat(arr);
    },
    setTimer(time) {
      let that = this;
      that.curCountDown = time || 60;
      this.interval = setInterval(function () {
        that.curCountDown = that.curCountDown - 1;
        if (that.curCountDown <= 0) {
          clearInterval(that.interval);
          that.$router.push({
            path: "character",
            query: { village_id: that.id },
          });
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background: #040b13;
  color: #ffffff;
}
.mt {
  margin-top: h(10);
}
.txtTop {
  margin-top: h(44);
}
.zanTop {
  margin-top: h(30);
}

.main {
  position: relative;
  padding: h(25) 20px;
  display: flex;
  .m__title {
    width: 100%;
    height: h(38);
    line-height: h(30);
    color: #44ffff;
    font-size: 22px;
    background: url("../assets/industry/明星企业标题@2x.png") no-repeat;
    background-size: 100% h(4);
    background-position: center bottom;
  }
  .overflow {
    overflow-y: scroll;
  }
  .m__list {
    display: flex;
    height: h(370);
    // overflow-y: scroll;
    &-item {
      width: 490px;
      display: flex;
      margin-top: h(35);
      img {
        width: 250px;
        height: h(148);
        border-radius: 6px;
      }
      .title {
        font-size: 20px;
        color: #ffffff;
        font-weight: 600;
      }
      .txt {
        font-size: 16px;
        line-height: h(22);
        color: rgba(255, 255, 255, 0.8);
      }
      .zan {
        display: inline-block;
        height: h(20);
        font-size: 16px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8);
        img {
          width: 21px;
          height: h(20);
        }
      }
      .zan-label {
        display: inline-block;
        width: 72px;
        height: h(29);
        font-size: 16px;
        border: 1px solid rgba(255, 255, 255, 0.7);
        color: rgba(255, 255, 255, 0.8);
        border-radius: 16px;
        text-align: center;
        line-height: h(28);
        em {
          font-style: normal;
          color: #56fefe;
        }
      }
    }
    &-item:nth-child(2n) {
      margin-left: 21px;
    }
  }
  .m__left {
    width: 1078px;
    &-top {
      width: 100%;
      height: h(456);
      background: url("../assets/industry/明星企业外框@2x.png") no-repeat;
      background-size: 100% 100%;
      padding: h(21) 30px;
      overflow: hidden;
    }
    &-bottom {
      width: 100%;
      height: h(456);
      background: url("../assets/industry/明星企业外框@2x.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 18px;
      padding: h(21) 30px;
      overflow: hidden;
    }
  }
  .m__right {
    width: 764px;
    margin-left: 18px;
    background: url("../assets/industry/产业概述外框@2x.png") no-repeat;
    background-size: 100% 100%;
    padding: h(21) 30px;
    overflow: hidden;
  }
  .m__instr {
    font-size: 18px;
    height: h(90);
    line-height: h(25);
    padding-top: h(20);
    margin-bottom: h(20);
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .m__image {
    display: flex;
    // overflow-y: scroll;
    .item {
      width: 330px;
      height: h(200);
      border-radius: 6px;
      margin-bottom: h(25);
      img {
        width: 332px;
        height: h(200);
        border-radius: 6px;
      }
    }
    .item:nth-child(2n) {
      margin-left: 20px;
    }
  }
  .code {
    position: absolute;
    width: 242px;
    height: h(130);
    padding: h(25) 20px;
    background: url("../assets/industry/浮窗_底板@2x.png");
    background-size: 100% 100%;
    right: 0;
    bottom: h(26);
    .txt {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
      .view {
        width: 24px;
        height: h(17);
        margin-right: 10px;
      }
      .click {
        width: 24px;
        height: h(24);
        margin-right: 10px;
      }
    }
    .icon {
      width: 94px;
      height: h(94);
    }
  }
}
.seamless-warp {
  width: 100%;
  height: h(360);
  margin-top: h(10);
  overflow: hidden;
}
</style>
